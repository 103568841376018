import { useGoogleOneTapLogin } from '@react-oauth/google';
import { AnalyticsEvent, AnalyticsEventName } from '../analytics/AnalyticsEvent';
import Auth, { SSOProvider } from './Auth';
import Logger from '../logging/logger';
import { AVATAR_STORAGE_KEY } from '../config/constants';

export interface GoogleUser {
    email: string;
    family_name: string;
    given_name: string;
    name: string;
    picture: string;
    provider: SSOProvider | undefined;
}

class Google {
    public static async logIn(response: GoogleUser) {
        const imageResponse = await fetch(response.picture, {
            mode: 'cors',
            credentials: 'omit',
            headers: {
                Accept: 'image/*',
            },
        });
        let base64: string = '';
        if (imageResponse.ok) {
            const blob = await imageResponse.blob();
            base64 = (await Auth.cacheAndConvertImage(AVATAR_STORAGE_KEY, blob)) || '';
        }
        Auth.commonLogIn(response.name, base64, !!base64, response.email, SSOProvider.GOOGLE);
    }
}

export const useGoogleOneTap = (onLogin: any, enabled: boolean) => {
    useGoogleOneTapLogin({
        promptMomentNotification: (notification: any) => {
            if (!Object.keys(notification).includes('g')) {
                return;
            }

            const event = notification['g'];
            switch (event) {
                case 'display':
                    {
                        const eventName = notification['h']
                            ? AnalyticsEventName.GOOGLE_ONE_TAP_SHOWN
                            : AnalyticsEventName.GOOGLE_ONE_TAP_DIDNT_SHOW;
                        AnalyticsEvent.create(eventName).mixpanelTrack();
                    }
                    break;
                case 'skipped':
                    AnalyticsEvent.create(AnalyticsEventName.GOOGLE_ONE_TAP_CLOSED).mixpanelTrack();
                    break;
                default:
                    return;
            }
        },
        onSuccess: (response) => {
            try {
                Auth.logIn(
                    SSOProvider.GOOGLE_ONE_TAP,
                    JSON.parse(atob((response.credential as string).split('.')[1]))
                );
                onLogin();
            } catch (error) {
                Auth.handleSSOFailure(SSOProvider.GOOGLE_ONE_TAP, error);
            }
        },
        onError: () => {
            Logger.error('in useGoogleOneTapLogin error : response = ');
        },
        disabled: !enabled,
        use_fedcm_for_prompt: Auth.isFedcmEnabled(),
        cancel_on_tap_outside: false,
    });
};

export default Google;
