import React from 'react';
import { Button, Card, Flex, Typography } from 'antd';
import styles from '../../../styles/components/screens/Signup.module.css';
import WebsiteLogo from '../../WebsiteLogo';
import Config from '../../../config/config';
import Auth, { SSOProvider } from '../../../auth/Auth';
import { useGoogleLogin } from '@react-oauth/google';
import { LinkedIn } from 'react-linkedin-login-oauth2';
import { FaFacebook, FaLinkedin, FaMicrosoft } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import axios from 'axios';
import { AnalyticsEvent, AnalyticsEventName } from '../../../analytics/AnalyticsEvent';
import { Microsoft } from '../../../auth/microsoft';
import { GoogleUser } from '../../../auth/google';

const { Title, Text } = Typography;

interface SignUpProps {
    onLogin: () => void;
}

const SignUp: React.FC<SignUpProps> = ({ onLogin }) => {
    const showLinkedin = false;
    const showFacebook = false;

    const googleLogin = useGoogleLogin({
        onSuccess: async (response) => {
            await axios
                .get<GoogleUser>('https://www.googleapis.com/oauth2/v3/userinfo', {
                    headers: { Authorization: `Bearer ${response.access_token}` },
                })
                .then((response) => Auth.logIn(SSOProvider.GOOGLE, response.data))
                .then(onLogin);
        },
        onError: () => Auth.handleSSOFailure(SSOProvider.GOOGLE, ''),
    });

    const microsoftLogin = async () => {
        Microsoft.logIn().then(async (response) => {
            await Auth.logIn(SSOProvider.MICROSOFT, response);
            onLogin();
        });
    };

    function facebookLogin() {}

    function afterLinkedInLogin(code: string) {
        console.debug('LinkedIn login: ', code);
    }

    const providers: Record<SSOProvider, () => void> = {
        [SSOProvider.GOOGLE]: googleLogin,
        [SSOProvider.LINKEDIN]: () => {} /* set on LinkedIn component */,
        [SSOProvider.FACEBOOK]: facebookLogin,
        [SSOProvider.GOOGLE_ONE_TAP]: () => {},
        [SSOProvider.MICROSOFT]: microsoftLogin,
    };

    const handleSSOButtonClick = (provider: SSOProvider) => {
        AnalyticsEvent.create(AnalyticsEventName.SSO_BUTTON_CLICKED)
            .set('sso', provider.toLowerCase())
            .mixpanelTrack();

        if (providers[provider]) {
            providers[provider]();
        }
    };
    const renderLoginButton = (provider: SSOProvider, ...args: any) => {
        const getIcon = () => {
            switch (provider) {
                case SSOProvider.GOOGLE:
                    return <FcGoogle size={30} />;
                case SSOProvider.LINKEDIN:
                    return <FaLinkedin size={30} color={'#0077b5'} />;
                case SSOProvider.FACEBOOK:
                    return <FaFacebook size={30} color={'#4267b2'} />;
                case SSOProvider.MICROSOFT:
                    return <FaMicrosoft size={30} color={'#0078d4'} />;
                default:
                    return <></>;
            }
        };

        return (
            <Button className={styles.ssoButton} onClick={() => handleSSOButtonClick(provider)}>
                <Flex align={'center'} style={{ width: '100%' }} gap={30}>
                    <Flex justify={'left'}>{getIcon()}</Flex>
                    <Flex align={'center'} justify={'left'}>
                        <Text>
                            Log in with{' '}
                            <span style={{ textTransform: 'capitalize' }}>{provider}</span>
                        </Text>
                    </Flex>
                </Flex>
            </Button>
        );
    };

    return (
        <Flex className={styles.loginContainer}>
            <Card className={styles.loginCard}>
                <div className={styles.loginHeader}>
                    <Flex vertical justify={'center'} gap={10} align={'center'}>
                        <WebsiteLogo size={80} />
                        <Title level={2} className={styles.signUpTitle}>
                            {Config.signUp.title}
                        </Title>
                        <Text>{Config.signUp.subTitle}</Text>
                    </Flex>
                </div>
                <Flex vertical justify={'center'} align={'center'} gap={16}>
                    {renderLoginButton(SSOProvider.GOOGLE)}
                    {showLinkedin && (
                        <LinkedIn
                            redirectUri={window.location.origin}
                            clientId={Auth.linkedInClientId}
                            scope={'profile'}
                            onSuccess={afterLinkedInLogin}
                            onError={(error) => Auth.handleSSOFailure(SSOProvider.LINKEDIN, error)}
                        >
                            {({ linkedInLogin }) => {
                                providers[SSOProvider.LINKEDIN] = linkedInLogin;
                                return renderLoginButton(SSOProvider.LINKEDIN);
                            }}
                        </LinkedIn>
                    )}
                    {renderLoginButton(SSOProvider.MICROSOFT)}
                    {showFacebook && renderLoginButton(SSOProvider.FACEBOOK)}
                </Flex>
            </Card>
        </Flex>
    );
};

export default SignUp;
