export enum ResponseTypeEnum {
    FROM_LIST = '0',
    FREE_TEXT = '1',
}

export const HOTJAR_VERSION: number = 6;

export const API_TIMEOUT = 60000; /* msec */
export const API_RETRIES = 3;
export const API_RETRY_DELAY = 500; /* msec */
export const NOT_AVAILABLE = 'N/A';

export const LOGO_SIZE = 85;

export const AVATAR_STORAGE_KEY = 'avatar';
export const FAKE_NAME_PREFIX = '🤖 ';
export const EXPERIMENT_NAME = 'experiment1';

export const CHAT_TIMEOUT = 30 * 60 * 1000; /* msec */
export const LANDING_PROP_PLACEHOLDER_STRING = 'XXX';
export const MICROSOFT_GRAPH_FETCH_TOKEN_URL = 'https://graph.microsoft.com/v1.0';
export const OUTLOOK_MAIL_SUFFIX = '@outlook.com';
export const MICROSOFT_SSO_AUTHORITY = 'https://login.microsoftonline.com/common';
