import Config from '../config/config';

enum Severity {
    INFO = 'INFO',
    ERROR = 'ERROR',
    DEBUG = 'DEBUG',
    WARN = 'WARN',
    TRACE = 'TRACE',
}

export interface ILogger {
    log(severity: Severity, message: string, ...args: any[]): void;
}

class Logger {
    private static instnace: Logger | null = new Logger();
    private loggers: ILogger[] = [];

    private constructor() {}

    public static registerLogger(logger: ILogger): void {
        Logger.instnace?.loggers.push(logger);
    }

    public static info(message: string, ...args: any[]): void {
        Logger.instnace?.broadcast(Severity.INFO, message, args);
        if (Config.isDevMode) {
            console.info(message + args.join(' '));
        }
    }

    public static error(message: string, ...args: any[]): void {
        Logger.instnace?.broadcast(Severity.ERROR, message, args);
        if (Config.isDevMode) {
            console.error(message + args.join(' '));
        }
    }

    public static debug(message: string, ...args: any[]): void {
        console.debug(Severity.DEBUG.toUpperCase() + ': ' + message + args.join(' '));
    }

    public static warn(message: string, ...args: any[]): void {
        console.warn(Severity.WARN.toUpperCase() + ': ' + message + args.join(' '));
    }
    private broadcast(severity: Severity, message: string, ...args: any[]): void {
        if (this.loggers.length === 0) {
            return;
        }

        this.loggers.forEach((logger) => {
            logger.log(severity, message, ...args);
        });
    }
}

class Timer {
    public static startTime: number = new Date().getTime();

    private static msToTime = (ms: number): string => {
        // Convert to seconds
        let seconds = Math.floor(ms / 1000);

        // Extract hours, minutes, seconds
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        const minutes = Math.floor(seconds / 60);
        seconds %= 60;
        const milli = ms % 1000;

        // Pad with zeros and format
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}.${milli.toString().padStart(3, '0')}`;
    };

    public static getDelta(): number {
        return new Date().getTime() - Timer.startTime;
    }

    public static getDeltaStr(): string {
        return Timer.msToTime(Timer.getDelta());
    }

    public static getTime() {
        return new Date().toISOString();
    }

    // Reset the timer
    public static reset() {
        Timer.startTime = new Date().getTime();
    }

    public static logTime(message?: string) {
        let msg = 'Time since program start: ' + Timer.getDeltaStr();
        if (message) {
            msg = msg + ' --- ' + message;
        }
        Logger.debug(msg);
        console.log(msg);
    }
}

export { Severity };
export default Logger;
export { Timer };
