import Config from '../config/config';
import { ScreenName } from '../providers/ContextProvider/ContextProvider';

export interface UTMParamsInterface {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
    utm_groupid?: string;
}

export interface FirstScreenConfig {
    isAutoPrompt: boolean;
    autoPrompt: string;
    initialTerm: string;
    screenName: ScreenName;
}

export class URLHandler {
    private static _firstScreenConfig: FirstScreenConfig | unknown = null;

    public static init(): void {
        let isAutoPrompt = false;
        let initialTerm = '';
        let screenName = ScreenName.UNDEFINED;

        let autoPrompt = '';
        const segments = URLParams.getUrlSegments();
        const utm_params: UTMParamsInterface = URLParams.getUTMParams();
        const prompts = Config.utmWhiteList.allowedFirstPrompt;

        /* Check if the URL contains at least one segment.
         *  E.g.: URL/seg1/seg2/.... */
        if (segments[0] != null && !segments[0].includes('token')) {
            autoPrompt = `${prompts['Defualt wrapper']} ${segments[0]}`;
            isAutoPrompt = true;
            screenName = ScreenName.SPLIT;
        } else if (utm_params.utm_term != null && URLParams.isWhitelisted(utm_params.utm_term)) {
            autoPrompt = prompts[utm_params.utm_term];
            isAutoPrompt = true;
            screenName = ScreenName.SPLIT;
        } else {
            screenName = ScreenName.HOME;
        }

        URLHandler._firstScreenConfig = {
            isAutoPrompt,
            autoPrompt: autoPrompt.replace(/_/g, ' '),
            initialTerm,
            screenName,
        };
    }

    public static get firstScreenConfig(): FirstScreenConfig {
        if (!URLHandler._firstScreenConfig) {
            URLHandler.init();
        }

        return URLHandler._firstScreenConfig as FirstScreenConfig;
    }
}

class URLParams {
    private static utmParams: UTMParamsInterface = {};
    private static hasUtmParams = false;
    public static userToken: string = '';
    public static urlSegments: string[] = [];
    public static readonly UTM_KEYS: (keyof UTMParamsInterface)[] = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_term',
        'utm_content',
        'utm_groupid',
    ];

    public static collectURLParams(strip: boolean = true) {
        const params = new URLSearchParams(window.location.search);
        const segments = window.location.pathname.split('/').filter(Boolean);

        // Strip only if URL contain terms.
        if (strip && null != params) {
            window.history.replaceState({}, '', window.location.pathname);
        }

        this.UTM_KEYS.forEach((key) => {
            const value = params.get(`${key}`);

            if (value) {
                this.utmParams[`${key}` as keyof UTMParamsInterface] = value;
                this.hasUtmParams = true;
            }

            this.userToken = params.get('token') || '';
        });

        this.urlSegments = segments;
    }

    public static clear() {
        this.utmParams = {};
        this.hasUtmParams = false;
    }

    public static getUTMParams(): UTMParamsInterface {
        return this.hasUtmParams ? this.utmParams : {};
    }

    public static getUserToken(): string {
        return this.userToken;
    }

    public static getUrlSegments(): string[] {
        return this.urlSegments;
    }

    public static isWhitelisted = (term: string): boolean => {
        const whitelist = Config.utmWhiteList;
        const white_keys = Object.keys(whitelist.allowedFirstPrompt);
        return white_keys.includes(term);
    };
}

export default URLParams;
