import React, { useState, useCallback, useRef, useEffect } from 'react';
import styles from '../../../styles/screen/SplitScreen/SplitScreen.module.css';
import '../../../styles/styles.css';

import ChatComponent from '../../Chat/Chat';
import Screen from '../Screen/Screen';
import EmptyData from '../../EmptyData/EmptyData';
import ComparisonTable from '../../Table/Table';
import { AnalyticsEvent, AnalyticsEventName } from '../../../analytics/AnalyticsEvent';
import { Flex } from 'antd';
import { useOneTimeCallback } from '../../../utils/Utils';
import Config from '../../../config/config';
import LandingPopup from '../../LandingPopup/LandingPopup';

interface SplitScreenProps {
    initialPrompt: string;
    isAutoPrompt: boolean;
    setIsAutoPrompt: React.Dispatch<React.SetStateAction<boolean>>;
    onTransition(): void;

    showPopup: boolean;
    setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
    initialTerm: string;
}

export enum InfoState {
    EMPTY,
    LOADING,
    LOADED,
}

export interface tool {
    toolName: string;
    state: InfoState;
    features?: string[];
}

const SplitScreen: React.FC<SplitScreenProps> = ({
    initialPrompt,
    isAutoPrompt,
    setIsAutoPrompt,
    onTransition,
    showPopup,
    setShowPopup,
    initialTerm,
}) => {
    const [headline, setHeadline] = useState<string>('');
    const [tools, setTools] = React.useState<tool[]>([]);
    const [isTableLoading, setIsTableLoading] = React.useState<boolean>(false);
    const [infoState, setInfoState] = React.useState<InfoState>(InfoState.EMPTY);
    const [showBanner, setShowBanner] = React.useState<boolean>(false);

    const TableCount = useRef(0);

    useEffect(() => {
        AnalyticsEvent.create(AnalyticsEventName.PAGE_LOADED_MAIN)
            .mixpanelTrack()
            .googleAnalyticsTrack();
    }, []);

    const handleToolNamesUpdate = (title: string, toolNames: string[], features: string[]) => {
        let event: AnalyticsEvent;
        if (!TableCount.current) {
            event = AnalyticsEvent.create(AnalyticsEventName.FIRST_TABLE_SHOWN)
                .set('number_of_columns', toolNames.length)
                .set('number_of_rows', Config.table.columns.length)
                .set('name_of_tools', toolNames.join(', '))
                .set('table_title', title)
                .googleAnalyticsTrack();
        } else {
            event = AnalyticsEvent.create(AnalyticsEventName.TABLE_UPDATED)
                .set('table_update_counter', TableCount.current)
                .set('table_title', title)
                .set('name_of_tools', toolNames.join(', '))
                .googleAnalyticsTrack();
        }
        event.set('$duration', Date.now() - event.get('$duration'));
        event.mixpanelTrack();
        for (let i = 0; i < Math.min(toolNames.length, Config.table.toolLimitCount); i++) {
            setTools((prevTools) => {
                prevTools.splice(i, 1, {
                    toolName: toolNames[i],
                    state: InfoState.LOADING,
                    features: features,
                });
                return [...prevTools];
            });
        }
        setInfoState(InfoState.LOADING);
        setIsTableLoading(true);
        TableCount.current++;
    };

    const handleHeadlineUpdate = (_headline: string) => {
        setHeadline(_headline);
    };

    const showBannerOnce = useOneTimeCallback(() => {
        setShowBanner(true);
    }, []);

    const handleAllColumnsLoaded = useCallback(() => {
        setIsTableLoading(false);
        setInfoState(InfoState.LOADED);
        showBannerOnce();
    }, [showBannerOnce]);

    return (
        <Screen bottomSeperator={true} showBanner={showBanner} setShowBanner={setShowBanner}>
            <LandingPopup
                visible={showPopup}
                onClose={() => setShowPopup(false)}
                term={initialTerm}
            />
            <Flex className={styles.flexContainer}>
                <Flex className={styles.chatContainer}>
                    <ChatComponent
                        initialPrompt={initialPrompt}
                        isAutoPrompt={isAutoPrompt}
                        setisAutoPrompt={setIsAutoPrompt}
                        onHeadlineUpdate={handleHeadlineUpdate}
                        onToolNamesUpdate={handleToolNamesUpdate}
                        showBanner={showBanner}
                    />
                </Flex>
                <Flex className={styles.tableContainer}>
                    {infoState === InfoState.EMPTY ? (
                        <div className={styles.emptyStateContainer}>
                            <EmptyData />
                        </div>
                    ) : (
                        <ComparisonTable
                            headline={headline}
                            tools={tools}
                            isLoading={isTableLoading}
                            onAllColumnsLoaded={handleAllColumnsLoaded}
                            showBanner={showBanner}
                        />
                    )}
                </Flex>
            </Flex>
        </Screen>
    );
};

export default SplitScreen;
