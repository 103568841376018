import Mixpanel from './Mixpanel';
import GoogleAnalytics from './GoogleAnalytics';

export interface IAnalyticsEvent {
    name: string;
    properties?: Record<string, any>;
}

export class AnalyticsEvent {
    protected readonly event: IAnalyticsEvent;

    protected constructor(event: IAnalyticsEvent) {
        this.event = event;
    }

    static create(name: string): AnalyticsEvent {
        return new AnalyticsEvent(getAnalyticsEvent(name));
    }

    set(key: string, value: any): AnalyticsEvent {
        if (this.event.properties) {
            this.event.properties[key] = value;
        }
        return this;
    }

    get(key: string): any {
        return this.event.properties ? this.event.properties[key] : undefined;
    }

    mixpanelTrack(): AnalyticsEvent {
        Mixpanel.track(this.event.name, this.event.properties);
        return this;
    }

    googleAnalyticsTrack(): AnalyticsEvent {
        GoogleAnalytics.track(this.event.name, this.event.properties);
        return this;
    }
}

export enum AnalyticsEventName {
    PAGE_LOADED_HOME = 'Page loaded - home',
    PAGE_LOADED_MAIN = 'Page loaded - main',
    PAGE_LOADED_UNSUPPORTED_DEVICE = 'Page loaded - unsupported device',
    BUTTON_CLICKED_SEARCH = 'Button clicked - search',
    BUTTON_CLICKED_LOGO_HOME = 'Button clicked - logo home',
    BUTTON_CLICKED_JOURNEY_SUGGESTION = 'Button clicked - journey suggestion',
    FIRST_TABLE_SHOWN = 'First table shown',
    TABLE_UPDATED = 'Table updated',
    CHAT_RESPONSE_BOT = 'Chat response - bot',
    CHAT_RESPONSE_USER = 'Chat response - user',
    CHAT_RESPONSE_AUTO = 'Chat response - auto',
    BUTTON_CLICKED_VENDOR_LINK = 'Button Clicked - Link to vendor',
    SERVER_REQUEST_FAILED = 'Server Request Failed',
    BANNER_CLICKED = 'Banner Clicked',
    BANNER_SEND_EMAIL_BUTTON = 'Banner Send Email Button',
    BANNER_CLOSE_X_BUTTON = 'Banner Close X Button',
    NOLOS_SESSION_START = 'Nolos Session Start',
    USER_INFORMATION = 'User Information',
    GOOGLE_ONE_TAP_SHOWN = 'Google One Tap Shown',
    GOOGLE_ONE_TAP_DIDNT_SHOW = 'Google One Tap Didnt Show',
    GOOGLE_ONE_TAP_CLOSED = 'Google One Tap Closed',
    SIGN_UP_BUTTON_CLICKED = 'Sign Up Button Clicked',
    SIGN_UP_SUCCESS = 'Sign Up Success',
    SSO_BUTTON_CLICKED = 'SSO Button Clicked',
    SIGN_UP_WINDOW_SHOWN = 'Sign Up Window Shown',
    SIGN_UP_WINDOW_CLOSED = 'Sign Up Window Closed',
    SSO_FAILED = 'SSO Failed',
    SIGN_OUT_BUTTON_CLICKED = 'Sign Out Button Clicked',
    FOOTER_CONTACT_US_LINK_CLICK = 'Footer Contact Us Link Click',
    LANDING_POPUP_SHOWN = 'Landing Popup Shown',
    LANDING_POPUP_CLOSED = 'Landing Popup Closed',
    LANDING_POPUP_SHOW_MY_BUTTON_CLICKED = 'Landing Popup Show My Button Clicked',
    LANDING_POPUP_SHOW_DIFFERENT_BUTTON_CLOSED = 'Landing Popup Show Different Button Closed',
}

export function getAnalyticsEvent(name: string): IAnalyticsEvent {
    return ANALYTICS_EVENTS[name];
}

const ANALYTICS_EVENTS: Record<string, IAnalyticsEvent> = {
    [AnalyticsEventName.NOLOS_SESSION_START]: {
        name: AnalyticsEventName.NOLOS_SESSION_START,
    },
    [AnalyticsEventName.PAGE_LOADED_HOME]: {
        name: AnalyticsEventName.PAGE_LOADED_HOME,
    },
    [AnalyticsEventName.PAGE_LOADED_MAIN]: {
        name: AnalyticsEventName.PAGE_LOADED_MAIN,
    },
    [AnalyticsEventName.PAGE_LOADED_UNSUPPORTED_DEVICE]: {
        name: AnalyticsEventName.PAGE_LOADED_UNSUPPORTED_DEVICE,
    },
    [AnalyticsEventName.BUTTON_CLICKED_SEARCH]: {
        name: AnalyticsEventName.BUTTON_CLICKED_SEARCH,
    },
    [AnalyticsEventName.BUTTON_CLICKED_LOGO_HOME]: {
        name: AnalyticsEventName.BUTTON_CLICKED_LOGO_HOME,
    },
    [AnalyticsEventName.BUTTON_CLICKED_JOURNEY_SUGGESTION]: {
        name: AnalyticsEventName.BUTTON_CLICKED_JOURNEY_SUGGESTION,
        properties: {
            location: 0,
            text: '',
        },
    },
    [AnalyticsEventName.FIRST_TABLE_SHOWN]: {
        name: AnalyticsEventName.FIRST_TABLE_SHOWN,
        properties: {
            number_of_columns: 0,
            number_of_rows: 0,
            name_of_tools: '',
            $duration: 0,
        },
    },
    [AnalyticsEventName.TABLE_UPDATED]: {
        name: AnalyticsEventName.TABLE_UPDATED,
        properties: {
            table_update_counter: 0,
            table_title: '',
            name_of_tools: '',
            $duration: 0,
        },
    },
    [AnalyticsEventName.CHAT_RESPONSE_BOT]: {
        name: AnalyticsEventName.CHAT_RESPONSE_BOT,
        properties: {
            message: '',
            message_number: 0,
            message_type: '',
            response_type: '',
            $duration: 0,
        },
    },
    [AnalyticsEventName.CHAT_RESPONSE_USER]: {
        name: AnalyticsEventName.CHAT_RESPONSE_USER,
        properties: {
            message: '',
            message_number: 0,
            message_type: '',
            last_message: '',
            follow_up: false,
            $duration: 0,
        },
    },
    [AnalyticsEventName.CHAT_RESPONSE_AUTO]: {
        name: AnalyticsEventName.CHAT_RESPONSE_AUTO,
        properties: {
            message: '',
            message_number: 0,
            message_type: '',
            last_message: '',
            follow_up: false,
            $duration: 0,
        },
    },
    [AnalyticsEventName.BUTTON_CLICKED_VENDOR_LINK]: {
        name: AnalyticsEventName.BUTTON_CLICKED_VENDOR_LINK,
        properties: {
            software_name: '',
            url: '',
            column_number: 0,
        },
    },
    [AnalyticsEventName.SERVER_REQUEST_FAILED]: {
        name: AnalyticsEventName.SERVER_REQUEST_FAILED,
        properties: {
            status_code: '',
            error_name: '',
            error_message: '',
            request: '',
        },
    },
    [AnalyticsEventName.BANNER_CLICKED]: {
        name: AnalyticsEventName.BANNER_CLICKED,
    },
    [AnalyticsEventName.BANNER_SEND_EMAIL_BUTTON]: {
        name: AnalyticsEventName.BANNER_SEND_EMAIL_BUTTON,
        properties: {
            email: '',
        },
    },
    [AnalyticsEventName.BANNER_CLOSE_X_BUTTON]: {
        name: AnalyticsEventName.BANNER_CLOSE_X_BUTTON,
    },

    [AnalyticsEventName.USER_INFORMATION]: {
        name: AnalyticsEventName.USER_INFORMATION,
        properties: {
            SW_domain: '',
            Organization_size: '',
            Experience_with_domain: '',
            Key_features: '',
            Relpacement_SW: '',
            Insufficient_data: '',
            Confidence: '',
        },
    },
    [AnalyticsEventName.GOOGLE_ONE_TAP_SHOWN]: {
        name: AnalyticsEventName.GOOGLE_ONE_TAP_SHOWN,
    },
    [AnalyticsEventName.GOOGLE_ONE_TAP_DIDNT_SHOW]: {
        name: AnalyticsEventName.GOOGLE_ONE_TAP_DIDNT_SHOW,
    },
    [AnalyticsEventName.GOOGLE_ONE_TAP_CLOSED]: {
        name: AnalyticsEventName.GOOGLE_ONE_TAP_CLOSED,
    },
    [AnalyticsEventName.SIGN_UP_BUTTON_CLICKED]: {
        name: AnalyticsEventName.SIGN_UP_BUTTON_CLICKED,
    },
    [AnalyticsEventName.SIGN_UP_SUCCESS]: {
        name: AnalyticsEventName.SIGN_UP_SUCCESS,
        properties: {
            type: '',
        },
    },
    [AnalyticsEventName.SSO_BUTTON_CLICKED]: {
        name: AnalyticsEventName.SSO_BUTTON_CLICKED,
        properties: {
            sso: '',
        },
    },
    [AnalyticsEventName.SIGN_UP_WINDOW_SHOWN]: {
        name: AnalyticsEventName.SIGN_UP_WINDOW_SHOWN,
    },
    [AnalyticsEventName.SIGN_UP_WINDOW_CLOSED]: {
        name: AnalyticsEventName.SIGN_UP_WINDOW_CLOSED,
    },
    [AnalyticsEventName.SSO_FAILED]: {
        name: AnalyticsEventName.SSO_FAILED,
    },
    [AnalyticsEventName.SIGN_OUT_BUTTON_CLICKED]: {
        name: AnalyticsEventName.SIGN_OUT_BUTTON_CLICKED,
    },
    [AnalyticsEventName.FOOTER_CONTACT_US_LINK_CLICK]: {
        name: AnalyticsEventName.FOOTER_CONTACT_US_LINK_CLICK,
    },
    [AnalyticsEventName.LANDING_POPUP_SHOWN]: {
        name: AnalyticsEventName.LANDING_POPUP_SHOWN,
    },
    [AnalyticsEventName.LANDING_POPUP_CLOSED]: {
        name: AnalyticsEventName.LANDING_POPUP_CLOSED,
    },
    [AnalyticsEventName.LANDING_POPUP_SHOW_MY_BUTTON_CLICKED]: {
        name: AnalyticsEventName.LANDING_POPUP_SHOW_MY_BUTTON_CLICKED,
        properties: {
            term: '',
        },
    },
    [AnalyticsEventName.LANDING_POPUP_SHOW_DIFFERENT_BUTTON_CLOSED]: {
        name: AnalyticsEventName.LANDING_POPUP_SHOW_DIFFERENT_BUTTON_CLOSED,
    },
};
