import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { Table, Flex, Skeleton } from 'antd';
import { getTool, ToolData } from '../../core/Core';
import styles from '../../styles/screen/SplitScreen/Table/Table.module.css';
import { columns, data, DataType, tableSummary } from './TableData';
import { tool, InfoState } from '../screens/SplitScreen/SplitScreen';
import Config from '../../config/config';
import { LOGO_SIZE } from '../../config/constants';
import Logger from '../../logging/logger';

interface ComparisonTableProps {
    headline: string;
    tools: tool[];
    isLoading: boolean;
    showBanner: boolean;
    onAllColumnsLoaded: () => void;
}

const ComparisonTable: React.FC<ComparisonTableProps> = ({
    headline,
    tools,
    isLoading,
    showBanner,
    onAllColumnsLoaded,
}) => {
    const [tableHeight, setTableHeight] = useState<number | string>('auto');
    const [loadedTools, setLoadedTools] = useState<ToolData[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    const [allColumnsLoaded, setAllColumnsLoaded] = useState<boolean>(false);

    useLayoutEffect(() => {
        const updateTableHeight = () => {
            // tableHeadline.height + headline.padding (16*2) + LOGO_SIZE + logo.padding + tableSummary.height
            const headerFooterLogoHeight = 24 + 50 + LOGO_SIZE + 32 + 65;
            const height = containerRef.current?.clientHeight
                ? `${containerRef.current.clientHeight - headerFooterLogoHeight}px`
                : 'auto';
            setTableHeight(height);
        };
        updateTableHeight();
        window.addEventListener('resize', updateTableHeight);
        return () => window.removeEventListener('resize', updateTableHeight);
    }, [loadedTools, showBanner, isLoading]);

    useEffect(() => {
        const abortController = new AbortController();
        let restart = false;

        const updateTools = async () => {
            try {
                for (let i = 0; i < Math.min(tools.length, Config.table.toolLimitCount); i++) {
                    // Check if we should abort
                    if (restart) return;

                    if (tools[i].state === InfoState.LOADING) {
                        // Pass the abort signal to getTool (assuming getTool is a fetch operation)
                        let tool: ToolData;

                        try {
                            tool = await getTool(tools[i], false, abortController.signal);
                        } catch (error) {
                            if (error === 'request aborted') {
                                console.debug('Request aborted');
                                return;
                            } else {
                                Logger.error('Error in handleFirstPrompt', error);
                                throw error;
                            }
                        }
                        // Check again before updating state
                        if (restart) return;

                        setLoadedTools((prevTools) => {
                            prevTools.splice(i, 1, tool);
                            return [...prevTools].sort((a, b) => b.matchScore - a.matchScore);
                        });
                        tools[i].state = InfoState.LOADED;
                    }
                }
                setAllColumnsLoaded(true);
            } catch (error) {
                if (error instanceof Error && error.name === 'AbortError') {
                } else {
                    // Handle other errors
                    Logger.error('Error loading tools:', error);
                }
            }
        };

        if (tools.length > 0) {
            if (tools.filter((tool) => tool.state === InfoState.LOADING).length === tools.length) {
                setAllColumnsLoaded(false);
                setLoadedTools([]);
            }
            void updateTools();
        } else {
            setLoadedTools([]);
        }

        // Cleanup function
        return () => {
            restart = true;
            abortController.abort('request aborted');
        };
    }, [tools]);

    const onAllColumnsLoadedRef = useRef(onAllColumnsLoaded);

    useEffect(() => {
        onAllColumnsLoadedRef.current = onAllColumnsLoaded;
    }, [onAllColumnsLoaded]);

    useEffect(() => {
        if (allColumnsLoaded) {
            onAllColumnsLoadedRef.current();
        }
    }, [allColumnsLoaded]);

    const renderColumns = () => {
        const baseColumns = columns(loadedTools, isLoading, Config.table);
        const skeletonColumns = tools.slice(loadedTools.length).map((_, index) => ({
            title: () => <Skeleton.Avatar active size={64} shape={'square'} />,
            dataIndex: `skeleton-${index}`,
            key: `skeleton-${index}`,
            align: 'center' as const,
            width: 200,
            render: () => <Skeleton.Input active size={'small'} />,
        }));

        return [...baseColumns, ...skeletonColumns];
    };

    return (
        <div ref={containerRef} className={styles.tableWrapper}>
            <Table<DataType>
                rowKey="rowKey"
                columns={renderColumns()}
                dataSource={data(loadedTools, Config.table)}
                pagination={false}
                scroll={{
                    y: tableHeight,
                }}
                bordered={false}
                className={styles.table}
                title={() => (
                    <Flex align="center" className={styles.tableHeadline}>
                        {headline}
                    </Flex>
                )}
                summary={() => tableSummary(loadedTools, Config.table)}
            />
        </div>
    );
};

export default ComparisonTable;
